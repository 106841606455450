
import { defineComponent, ref, inject } from "vue";

import dateFormat from "@/utils/dateFormat";
import Empty from "@/components/common/Empty.vue";
export default defineComponent({
  name: "personalLetter",
  props: {
    userData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    Empty,
  },
  setup() {
    const userInfo = inject("userInfo") as any;

    return {
      userInfo,
      dateFormat,
    };
  },
});
